import Vue from "vue/dist/vue.min";

const DateCountdown = () => import(/* webpackChunkName: "DateCountdown" */ "./DateCountdown.vue");

function init() {
    document.querySelectorAll('[data-vue="countdown"]').forEach((el) => {
        new Vue({
            el,

            components: { DateCountdown },
        });
    });
}

if (document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", () => {
        init();
    });
}
